<template>
  <div class="contents-area account-contents">
    <div class="full-content">
      <div class="container">
        <div class="h1-breadcrumbs">
          <h1>{{ translations("MyProfile") }}</h1>
          <Breadcrumbs />
        </div>
        <div class="account-panel">
          <AccountNavigation />
          <div class="account-page-contents">
            <div class="accout-page-slider" :key="$route.meta.subkey">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AccountNavigation from "@/components/account/AccountNavigation";

export default {
  name: "Account",
  components: {
    AccountNavigation,
  },
  metaInfo() {
    return {
      title: this.translations("AccountPage"),
    };
  },
  computed: {
    ...mapGetters(["translations", "isUserLogged"]),
  },
};
</script>