<template>
  <div class="account-navigation">
    <div class="avatag-menu">
      <div class="avatar-cont">
        <span class="avatar">
          <span class="border">
            <svg
              v-if="!isUserLogged || !ListLoggedUser.avatar"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.33398 18.3333C3.33398 16.5652 4.03636 14.8695 5.28661 13.6193C6.53685 12.369 8.23254 11.6666 10.0007 11.6666C11.7688 11.6666 13.4645 12.369 14.7147 13.6193C15.9649 14.8695 16.6673 16.5652 16.6673 18.3333H15.0007C15.0007 17.0072 14.4739 15.7355 13.5362 14.7978C12.5985 13.8601 11.3267 13.3333 10.0007 13.3333C8.67457 13.3333 7.4028 13.8601 6.46512 14.7978C5.52744 15.7355 5.00065 17.0072 5.00065 18.3333H3.33398ZM10.0007 10.8333C7.23815 10.8333 5.00065 8.59581 5.00065 5.83331C5.00065 3.07081 7.23815 0.833313 10.0007 0.833313C12.7632 0.833313 15.0007 3.07081 15.0007 5.83331C15.0007 8.59581 12.7632 10.8333 10.0007 10.8333ZM10.0007 9.16665C11.8423 9.16665 13.334 7.67498 13.334 5.83331C13.334 3.99165 11.8423 2.49998 10.0007 2.49998C8.15898 2.49998 6.66732 3.99165 6.66732 5.83331C6.66732 7.67498 8.15898 9.16665 10.0007 9.16665Z"
                fill="currentColor"
              />
            </svg>
            <img
              v-if="isUserLogged && ListLoggedUser.avatar"
              :src="'//' + serverLink + ListLoggedUser.avatar"
              alt=""
            />
          </span>
        </span>
      </div>
      <div class="menu">
        <div
          :class="[
            'slider-line',
            { hidden: sliderPos.top === 0 && sliderPos.height === 0 },
          ]"
          :style="
            'top:' + sliderPos.top + 'px; height:' + sliderPos.height + 'px;'
          "
        ></div>
        <router-link
          @click.native="setSlider($event)"
          to="/account/products"
          exact-path
        >
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 2C10.1935 2 11.3381 2.47411 12.182 3.31802C13.0259 4.16193 13.5 5.30653 13.5 6.5V7.25H16.5V8.75H15.6248L15.057 15.5623C15.0414 15.7497 14.9559 15.9244 14.8176 16.0518C14.6792 16.1791 14.4981 16.2499 14.31 16.25H3.69C3.50194 16.2499 3.32077 16.1791 3.18242 16.0518C3.04406 15.9244 2.95861 15.7497 2.943 15.5623L2.3745 8.75H1.5V7.25H4.5V6.5C4.5 5.30653 4.97411 4.16193 5.81802 3.31802C6.66193 2.47411 7.80653 2 9 2ZM14.1195 8.75H3.87975L4.38 14.75H13.6193L14.1195 8.75ZM9 3.5C8.23032 3.5 7.49008 3.79583 6.93239 4.32629C6.3747 4.85676 6.04223 5.58128 6.00375 6.35L6 6.5V7.25H12V6.5C12 5.73032 11.7042 4.99008 11.1737 4.43239C10.6432 3.8747 9.91872 3.54223 9.15 3.50375L9 3.5Z"
              fill="currentColor"
            />
          </svg>
          {{ translations("MyOrders") }}
        </router-link>
        <router-link
          @click.native="setSlider($event)"
          to="/account/documents"
          exact-path
        >
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.25 5V2.75C5.25 2.55109 5.32902 2.36032 5.46967 2.21967C5.61032 2.07902 5.80109 2 6 2H15C15.1989 2 15.3897 2.07902 15.5303 2.21967C15.671 2.36032 15.75 2.55109 15.75 2.75V13.25C15.75 13.4489 15.671 13.6397 15.5303 13.7803C15.3897 13.921 15.1989 14 15 14H12.75V16.25C12.75 16.664 12.4125 17 11.9948 17H3.00525C2.90635 17.0006 2.8083 16.9816 2.71674 16.9442C2.62519 16.9068 2.54192 16.8517 2.47174 16.782C2.40156 16.7123 2.34584 16.6294 2.30779 16.5381C2.26974 16.4468 2.2501 16.3489 2.25 16.25L2.25225 5.75C2.25225 5.336 2.58975 5 3.0075 5H5.25ZM3.75225 6.5L3.75 15.5H11.25V6.5H3.75225ZM6.75 5H12.75V12.5H14.25V3.5H6.75V5Z"
              fill="currentColor"
            />
          </svg>
          {{ translations("MyDocuments") }}
        </router-link>
        <router-link
          @click.native="setSlider($event)"
          to="/account/profile"
          exact-path
        >
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 17C3 15.4087 3.63214 13.8826 4.75736 12.7574C5.88258 11.6321 7.4087 11 9 11C10.5913 11 12.1174 11.6321 13.2426 12.7574C14.3679 13.8826 15 15.4087 15 17H13.5C13.5 15.8065 13.0259 14.6619 12.182 13.818C11.3381 12.9741 10.1935 12.5 9 12.5C7.80653 12.5 6.66193 12.9741 5.81802 13.818C4.97411 14.6619 4.5 15.8065 4.5 17H3ZM9 10.25C6.51375 10.25 4.5 8.23625 4.5 5.75C4.5 3.26375 6.51375 1.25 9 1.25C11.4862 1.25 13.5 3.26375 13.5 5.75C13.5 8.23625 11.4862 10.25 9 10.25ZM9 8.75C10.6575 8.75 12 7.4075 12 5.75C12 4.0925 10.6575 2.75 9 2.75C7.3425 2.75 6 4.0925 6 5.75C6 7.4075 7.3425 8.75 9 8.75Z"
              fill="currentColor"
            />
          </svg>
          {{ translations("MyPersonalDetails") }}
        </router-link>
        <router-link
          @click.native="setSlider($event)"
          to="/account/yurlitsa"
          exact-path
        >
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.25 4.25V2C5.25 1.80109 5.32902 1.61032 5.46967 1.46967C5.61032 1.32902 5.80109 1.25 6 1.25H12C12.1989 1.25 12.3897 1.32902 12.5303 1.46967C12.671 1.61032 12.75 1.80109 12.75 2V4.25H15.75C15.9489 4.25 16.1397 4.32902 16.2803 4.46967C16.421 4.61032 16.5 4.80109 16.5 5V15.5C16.5 15.6989 16.421 15.8897 16.2803 16.0303C16.1397 16.171 15.9489 16.25 15.75 16.25H2.25C2.05109 16.25 1.86032 16.171 1.71967 16.0303C1.57902 15.8897 1.5 15.6989 1.5 15.5V5C1.5 4.80109 1.57902 4.61032 1.71967 4.46967C1.86032 4.32902 2.05109 4.25 2.25 4.25H5.25ZM15 10.25H3V14.75H15V10.25ZM15 5.75H3V8.75H5.25H6.75H11.25H12.75H15V5.75ZM6.75 2.75V4.25H11.25V2.75H6.75Z"
              fill="currentColor"
            />
          </svg>
          {{ translations("MyYurLitsa") }}
        </router-link>
        <a href="#" @click.prevent="logout">
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.75 17C3.55109 17 3.36032 16.921 3.21967 16.7803C3.07902 16.6397 3 16.4489 3 16.25V2.75C3 2.55109 3.07902 2.36032 3.21967 2.21967C3.36032 2.07902 3.55109 2 3.75 2H14.25C14.4489 2 14.6397 2.07902 14.7803 2.21967C14.921 2.36032 15 2.55109 15 2.75V5H13.5V3.5H4.5V15.5H13.5V14H15V16.25C15 16.4489 14.921 16.6397 14.7803 16.7803C14.6397 16.921 14.4489 17 14.25 17H3.75ZM13.5 12.5V10.25H8.25V8.75H13.5V6.5L17.25 9.5L13.5 12.5Z"
              fill="currentColor"
            />
          </svg>
          {{ translations("Logout") }}
        </a>
      </div>
    </div>
    <div class="language-choose">
      <LanguageChoose />
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import { mapGetters, mapActions } from "vuex";
import LanguageChoose from "@/components/app/LanguageChoose";

export default {
  name: "AccountNavigation",
  components: {
    LanguageChoose,
  },
  data: () => ({
    serverLink: VUE_APP_BACK_URL,
    sliderPos: {
      top: 0,
      height: 0,
    },
  }),
  computed: {
    ...mapGetters(["translations", "ListLoggedUser", "isUserLogged"]),
  },
  methods: {
    ...mapActions(["makeLogout", "clearUser"]),
    async logout() {
      await this.clearUser();
      await this.makeLogout();
      this.$router.push("/login");
    },
    setSlider(event) {
      this.sliderPos.top = event.target.offsetTop;
      this.sliderPos.height = event.target.offsetHeight;
    },
    clearSlider() {
      this.sliderPos.top = 0;
      this.sliderPos.height = 0;
    },
  },
  mounted() {
    let el = document.querySelector(".menu a.router-link-exact-active");
    if (el) {
      this.sliderPos.top = el.offsetTop;
      this.sliderPos.height = el.offsetHeight;
    } else {
      this.sliderPos.top = 0;
      this.sliderPos.height = 0;
    }
  },
};
</script>